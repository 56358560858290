<template>
  <div class="home">
    <div class="home__left">
      <div class="home__left--head">
        <el-tabs v-model="activeName" @tab-click="tabClick">
          <el-tab-pane
            :label="item.businessAreaName"
            :name="item.id"
            :key="item.id"
            v-for="item in areaLs"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div
        class="home__left--body"
        v-infinite-scroll="getList"
        :infinite-scroll-immediate="false"
        v-loading="loading"
      >
        <div class="home__left--body__area">
          <areaItem
            v-for="item in list"
            :areaItem="item"
            :key="item.id"
            :activeArea="businessTableId === item.id"
            @click="shopTableOrder(item)"
          />
        </div>
      </div>
    </div>
    <transition name="el-fade-in">
      <div class="home__right">
        <orderData
          :orderLs="orderLs"
          @invoicing="invoicing"
          @cancelOrder="cancelOrder"
          @cleanMachine="cleanMachine"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import businessTableArea from '@/service/businessTableArea.js'
import Debounce from '@/utils/debounce.js'
import areaItem from '@/components/areaItem/index.vue'
import orderData from '@/components/orderData/index.vue'
import businessTableInfo from '@/service/businessTableInfo.js'
import orderInfo from '@/service/orderInfo.js'
import uniPay from '@/utils/pay.js'
import { mapState } from 'vuex'
const DebounceFun = new Debounce()
export default {
  components: {
    orderData,
    areaItem
  },
  data () {
    return {
      value1: '',
      activeName: '',
      areaLs: [], // 区域列表
      orderLs: [],
      finished: false, // 是否加载完成，
      loading: false, // 是否加载中,
      list: [], // 桌台数据
      status: 'loadmore',
      isEmptyTable: false,
      businessTableId: '',
      pageData: {
        pageNum: 1,
        pageSize: 20,
        businessAreaId: ''
      }
    }
  },
  computed: {
    ...mapState({
      shopInfo: (store) => store.login.shopInfo,
      message: (store) => store.login.message
    })
  },
  watch: {
    message: {
      handler () {
        DebounceFun.debounceCD(() => {
          console.log(12121)
        }, 1000)
      },
      deep: true
    }
  },
  mounted () {
    this.$eventBus.$on('invoicing', (e) => this.invoicing(e))
    this.getAreaLs()
  },
  beforeDestroy () {
    this.$eventBus.$off('invoicing')
  },
  methods: {
    // 获取区域数据
    async getAreaLs () {
      const res = await businessTableArea.businessAreaList({
        businessAreaType: 0,
        businessId: this.shopInfo.id
      })
      this.areaLs = res.data
      this.pageData.businessAreaId = res.data.length > 0 ? res.data[0].id : ''
      this.activeName = res.data.length > 0 ? res.data[0].id : ''
      console.log(this.pageData)
      this.getList()
    },
    tabClick (e) {
      console.log(11111111111111)
      this.pageData.businessAreaId = this.activeName
      this.confirm()
    },
    // 重置数据并且重新请求桌台数据
    confirm () {
      this.finished = false
      this.loading = false
      this.list = []
      this.status = 'loadmore'
      this.pageData.pageNum = 1
      this.getList()
    },
    // 获取商户桌台列表
    async getList () {
      if (this.finished || this.loading || !this.pageData.businessAreaId) {
        return
      }
      const data = {
        ...this.pageData,
        businessId: this.shopInfo.id,
        keyword: this.keyword
      }
      this.loading = true
      this.status = 'loading'
      const res = await businessTableInfo.businessTableInfoPage(data)
      this.list = [...this.list, ...res.data.list]
      if (this.list.length >= res.data.total) {
        this.finished = true
        this.status = 'nomore'
      } else {
        this.status = 'loadmore'
      }
      this.pageData.pageNum = this.pageData.pageNum + 1
      this.loading = false
    },
    // 点击左边主体
    setOrderLs () {
      this.businessTableId = ''
      this.orderLs = []
    },
    /**
     * @description 点击桌子，查询桌子存在的订单并且打开订单弹窗
     * @param {Object} item  点中桌子的详情数据
     */
    async shopTableOrder (item) {
      this.loading = true
      const formData = {
        businessId: this.shopInfo.id,
        businessTableId: item.id,
        orderStatus: 0,
        pageSize: 100
      }
      this.businessTableId = item.id
      const res = await orderInfo.orderInfoPageOrder(formData)
      this.loading = false

      // 当前订单不存在订单
      if (res.data.total === 0) {
        this.$message({
          message: '该桌台暂无订单',
          type: 'warning'
        })
        this.orderLs = []
        return
      }
      console.log(res.data.list)
      // 订单存在
      this.orderLs = res.data.list
    },
    cancelOrder (item) {
      const str = item.payType !== 4 ? '是否取消订单并退款' : '是否取消订单并退还现金'
      const cancelStr = item.orderStatus === 1 ? str : '是否确认取消订单'
      this.$confirm(cancelStr, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.loading = true
        // 微信支付，并且已经支付
        if (item.orderStatus === 1 && item.payType !== 4) {
          const refundRes = await uniPay.refund({
            outTradeNo: item.outTradeNo,
            actualPrice: item.actualPrice,
            orderInfoId: item.id,
            orderInfo: item
          })
          if (!refundRes.success) {
            this.loading = false
            return
          }
        }

        this.loading = false
        this.refundOrder(item)
      })
    },
    // 清台
    async cleanMachine (item) {
      const confirm = await this.$confirm('客户已结账,是否确定清台?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      // 已经退还现金，修改订单状态
      if (confirm === 'confirm') {
        const res = await orderInfo.changeOrderStatus({
          id: item.id,
          orderStatus: 3
        })
        if (res.success) {
          this.$message({
            message: '订单已经完成',
            type: 'success'
          })
          this.shopTableOrder({
            id: item.businessTableId
          })
          this.confirm()
        }
      }
    },
    invoicing (item) {
      this.$message({
        message: '订单已经完成',
        type: 'success'
      })
      console.log(item)
      this.shopTableOrder({
        id: item.businessTableId
      })
      this.confirm()
    },
    async refundOrder (item) {
      const res = await orderInfo.changeOrderStatus({
        id: item.id,
        orderStatus: item.orderStatus !== 1 ? 4 : 5
      })
      if (res.success) {
        this.$message({
          message: '订单取消成功',
          type: 'success'
        })
        this.printOrder({
          type: item.orderStatus === 1 ? 2 : 1,
          orderId: item.id,
          voice: 0
        })
        this.shopTableOrder({
          id: item.businessTableId
        })
        this.confirm()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  display: flex;
  &__left {
    height: 100%;
    background: #ffffff;
    border-radius: 24px;
    flex: 1;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
    &--head {
      padding: 0px 20px;
      box-sizing: border-box;
      ::v-deep .el-tabs__item {
        height: 87px !important;
        line-height: 87px !important;
        color: #000000;
        font-weight: 700;
        font-size: 20px;
        width: 154px;
        text-align: center;
        padding: 0px !important;
      }
      ::v-deep .el-tabs__active-bar {
        width: 154px !important;
        background-color: #ffbb12 !important;
      }
    }
    &--body {
      flex: 1;
      min-height: 0px;
      width: 100%;
      overflow: auto;
      padding: 20px;
      box-sizing: border-box;
      &__area {
        width: 100%;
      }
    }
  }
  &__right {
    width: 671px;
    height: 100%;
    margin-left: 56px;
  }
}
::v-deep .el-tabs__nav-next {
  line-height: 87px !important;
}
::v-deep .el-tabs__nav-prev {
  line-height: 87px !important;
}
</style>
